export class NumberUtils {
  static padStart(value: number, maxSize: number, padValue: string): string {
    return ('' + value).padStart(maxSize, padValue);
  }

  static roundToUpTo2Decimals(val: number): string {
    return val.toFixed(2).replace('.', ',');
  }

  static roundToUpTo2DecimalsKeepWhole(val: number): string {
    return val % 1 === 0 ? val.toString() : val.toFixed(2).replace('.', ',');
  }
}
